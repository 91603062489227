<template>
  <span>
    <span>{{ prefixText }}{{ fileList.length > 1 ? 's' : '' }}:</span>
    <v-chip
      v-for="file in fileList"
      :key="file.pk"
      class="ma-2"
      :color="color"
      text-color="white"
      small
    >
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            v-if="allowDownload"
            left
            v-on="on"
            @click.stop="
              downloadFile({
                url: file.url,
                file: file.file,
              })
            "
          >
            mdi-download
          </v-icon>
        </template>
        <span>Download File</span>
      </v-tooltip>
      {{ file["file"] }}
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-if="allowDelete" right v-on="on" @click="removeFile(file.pk)">
            mdi-close
          </v-icon>
        </template>
        <span>Delete File</span>
      </v-tooltip>
    </v-chip>
    <v-chip
      v-if="fileList && allowDeleteAll"
      class="ma-2"
      color="warning"
      text-color="white"
      small
      @click.stop="removeAll"
    >
      <v-icon left>
        mdi-alert-circle-outline
      </v-icon>
      {{ removeAllText }}
    </v-chip>
    <v-chip
      v-if="fileList && fileList.length > 1 && allowDownloadZip"
      class="ma-2"
      color="warning"
      text-color="white"
      small
      @click.stop="downloadZip"
    >
      <v-icon left>
        mdi-download
      </v-icon>
      {{ `Download ZIP file` }}
    </v-chip>
  </span>
</template>

<script>
export default {
  name: "GerberFileChip",
  props: {
    prefixText: {
      type: String,
      default: "Current File"
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    fileList: Array,
    allowDelete: {
      type: Boolean,
      default: false,
    },
    allowDownload: {
      type: Boolean,
      default: false,
    },
    allowDeleteAll: {
      type: Boolean,
      default: false,
    },
    allowDownloadZip: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary"
    },
    removeAllText: {
      type: String,
      default: "Delete all files"
    },
  },
  methods: {
    downloadFile(payload) {
      this.$emit("download", payload);
    },
    removeFile(pk) {
      this.$emit("removeFile", pk);
    },
    removeAll() {
      this.$emit("removeAll");
    },
    downloadZip() {
      this.$emit("downloadZip");
    },
  },
};
</script>

<style></style>
